


































import { Vue, Component, VModel, Prop, Watch } from 'vue-property-decorator'
import { Freq, MeetingsGetFrequencyDescriptionParams } from '@tada-team/tdproto-ts'
import { meetings } from 'td-api'

const { getFrequencyDescription } = meetings

@Component({
  components: {
    RepeatabilityType: () => import('./Type.vue'),
    RepeatabilityDays: () => import('./Days.vue'),
    RepeatabilityFrequency: () => import('./Frequency.vue'),
    RepeatabilityDescription: () => import('../Info.vue'),
    RepeatabilitySwitcher: () => import('./Switcher.vue'),
  },
})
export default class Repeatability extends Vue {
  @VModel({ type: Freq }) private freq!: Freq | null

  @Prop({
    required: true,
    type: String,
  }) startDate!: string

  @Prop({
    type: Boolean,
  }) isEditDialog?: boolean

  private currentDay = 1

  private created () {
    this.currentDay = new Date(this.startDate).getDay()
  }

  @Watch('startDate')
  private watchStartDate () {
    const day = new Date(this.startDate).getDay()
    this.currentDay = day
    if (this.freq && this.freq.freqDays && !this.freq.freqDays.includes(day)) {
      this.freq.freqDays.push(day)
      this.updateFrequencyDescription()
    }
  }

  get isRepeatability () {
    return !!this.freq
  }

  set isRepeatability (val: boolean) {
    if (val) {
      window.goal('Calendar', {
        Calendar: this.isEditDialog
          ? 'Окно редактирования — включить «Повторять»'
          : 'Окно создания — включить «Повторять»',
      })
      this.freq = new Freq(1, 'MEETING_PRESENCE_STATUS_WEEKLY', [this.currentDay])
    } else {
      window.goal('Calendar', {
        Calendar: this.isEditDialog
          ? 'Окно редактирования — отключить «Повторять»'
          : 'Окно создания — отключить «Повторять»',
      })
      this.freq = null
    }
  }

  private async updateFrequencyDescription () {
    if (!this.freq) return
    const params = new MeetingsGetFrequencyDescriptionParams(
      this.freq.frequency || 1,
      this.freq.repeatabilityType,
      this.freq.freqDays?.join(',') || undefined,
    )
    this.freq.repeatabilityDescription = await getFrequencyDescription(params)
  }

  @Watch('freq')
  private async watchFreq () {
    await this.updateFrequencyDescription()
  }

  private get isWeeklyRepeatability () {
    return this.freq?.repeatabilityType === 'MEETING_PRESENCE_STATUS_WEEKLY'
  }

  get repeatabilityDescription () {
    if (!this.freq?.repeatabilityDescription) return ''
    return `${this.$t('meetings.repeatability.repeat')}
     ${this.freq.repeatabilityDescription ?? ''}`
  }
}
